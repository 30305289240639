import { Trans, useTranslation } from 'react-i18next';

function Test() {

    const [t, i18n] = useTranslation();
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    }

    return <>
        <a href="/"><Trans i18nKey="link.label" /></a>

        <button onClick={() => changeLanguage('de')}>German</button>
        <button onClick={() => changeLanguage('en')}>English</button>
    </>;
}

export default Test;