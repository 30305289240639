import React, { useState } from "react";
import { Trans } from "react-i18next";
import {
    BrowserRouter as Router, NavLink
} from "react-router-dom";

export default (props) => {

    const [mobileOpen, setMobileOpen] = useState(false);

    return <>

        <nav className={(mobileOpen ? " open" : "")}>

            <div className="mobilebranding"><img src="https://globalstore.tubitor.com/imgs/logo-light.svg" alt="Tubitor Logo" /></div>

            <div className={"nav-content"}>
                <NavigationLink to="/" label={<img src="https://globalstore.tubitor.com/imgs/logo-light.svg" alt="Tubitor Logo" />} />
                {/* <NavigationLink to="/services" label={"navigation.services"} /> */}
                <NavigationLink to="/about" label={"navigation.about"} />
                {/* <NavigationLink to="/blog" label={"navigation.blog"} /> */}
                {/* <NavigationLink to="/support" label={"navigation.support"} /> */}
                <NavigationLink to="/mytubitor" label={"navigation.mytubitor"} />
            </div>

            <div className={"mobile"} onClick={() => setMobileOpen(!mobileOpen)}>
                <span></span>
            </div>

        </nav>

    </>

}

function NavigationLink(props) {

    return <>

        <NavLink to={props.to} activeClassName="active" exact>
            <span><Trans i18nKey={props.label} /></span>
        </NavLink>

    </>

}