import { useState } from "react";
import { useTranslation } from "react-i18next"
import ReactCountryFlag from "react-country-flag";
import Link from "../pages/Link";

export default (props) => {

    const [t] = useTranslation();

    const [langOpen, setLangOpen] = useState(false);

    const changeLang = (lang) => {
        props.lang(lang);
        setLangOpen(false);
    };

    return <>

        <footer>

            <div className="footercontent">

                <div className="footerline">

                    <p>
                        &copy; {new Date().getFullYear()} {t('footer.copyright')}
                    &nbsp;| <Link to={'https://policies.tubitor.com/imprint'}>{t('footer.imprint')}</Link>
                    &nbsp;| <Link to={'https://policies.tubitor.com/privacy'}>{t('footer.privacy')}</Link>
                    &nbsp;| <Link to={'https://policies.tubitor.com/cookies'}>{t('footer.cookies')}</Link>
                    </p>

                </div>

                <div className="lang">

                    <div
                        className={"languageSwitch" + (langOpen ? " open" : "")}
                        onClick={() => setLangOpen(!langOpen)}
                    >
                        {t('footer.language')}
                        <div className="selection">
                            <div onClick={() => changeLang('en')}>
                                <ReactCountryFlag countryCode="US" svg />
                                English
                            </div>
                            <div onClick={() => changeLang('de')}>
                                <ReactCountryFlag countryCode="DE" svg />
                                German
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </footer>

    </>

}