import { Trans, useTranslation } from 'react-i18next';
import { BrowserRouter, Link, Redirect, Route, Router, Switch } from 'react-router-dom';
import Navigation from './components/navigation/Navigation';
import logo from './logo.svg';
import Test from './Test';

import './components/App.scss';

import Home from './components/pages/home/Home';
import Footer from './components/footer/Footer';
import Hero from './components/pages/home/Hero';

function App() {

  const [t, i18n] = useTranslation();
  const changeLanguageGlobally = (language) => {
    i18n.changeLanguage(language);
  }

  return (
    <BrowserRouter>

      <Navigation />

      <div id="pageinner">
        <Switch>
          <Route path="/" exact component={Home} />
          {/* <Route path="/services" exact></Route> */}
          <Route path="/about" exact><RedirectExt to="https://tubitor.info" /></Route>
          {/* <Route path="/blog" exact></Route> */}
          <Route path="/mytubitor" exact><RedirectExt to="https://my.tubitor.com/dash" /></Route>
          <Route path="*">
            <Hero
              title={t('content.error.404.title')}
              desc={t('content.error.404.desc')}
              buttonLink="/"
              buttonText={t('content.error.404.button')}
            />
          </Route>
        </Switch>
      </div>

      <Footer lang={changeLanguageGlobally} />

    </BrowserRouter>
  );
}

function RedirectExt(props) {
  window.location.href = props.to;
  return (<></>);
}

export default App;
