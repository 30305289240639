import { Trans, useTranslation } from "react-i18next"
import Hero from "./Hero"

export default (props) => {

    const [t] = useTranslation();

    return <>

        <Hero
            title={t('content.home.hero.title')}
            desc={t('content.home.hero.desc')}
            buttonLink={t('content.home.hero.button.link')}
            buttonText={t('content.home.hero.button.label')}
        />

    </>

}