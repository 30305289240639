import { useEffect } from 'react';
import Link from '../Link';

export default (props) => {

    return <>

        <div className="hero">

            <div className="container">

                <h1>{props.title}</h1>

                <p>{props.desc}</p>

                <Link className="button" to={props.buttonLink}>{props.buttonText}</Link>

            </div>

        </div>

    </>
}