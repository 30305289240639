import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { LangLoader } from './i18n';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div>We're sorry, but you can't view this page currently due to an error.</div>}>
      <LangLoader>
        <App />
      </LangLoader>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);