import i18n from "i18next";
import Backend from "i18next-xhr-backend";
// import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEN from './locales/en/translation.json';
import translationDE from './locales/de/translation.json';
import { useEffect, useState } from "react";

const resources = {
    'en': {
        translation: translationEN
    },
    'en-US': {
        translation: translationEN
    },
    'de': {
        translation: translationDE
    },
    'de-DE': {
        translation: translationDE
    },
};



export default {};

export function LangLoader(props) {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        i18n.use(Backend)
            .use(LanguageDetector)
            .use(initReactI18next)
            .init({
                resources,
                debug: false,
                load: 'languageOnly',
                ns: ['translation', 'main'],
                fallbackLng: 'en',
                defaultNS: 'translation',
                load: 'currentOnly',
                interpolation: {
                    escapeValue: false,
                },
                detection: {
                    order: ['cookie', 'navigator', 'localStorage', 'subdomain', 'queryString', 'htmlTag'],
                },
                react: {
                    wait: true
                },
            }, (err, t) => {
                // console.log(err, t);
                setLoading(false);
            });
    }, []);

    if (loading) return <h1>We prepare everything for you...</h1>

    return props.children;

}